import * as mdb from 'mdb-ui-kit';
import actions from './action';
import fire from './fire';

console.log('actions', actions);

const $ = document.querySelector.bind(document);

const burningWizard = $('#burningWizard');
const stepper = mdb.Stepper.getOrCreateInstance($('#stepper-burning-wizard'));
const box_flames = $('#box-flame-cards');
const card_flame = box_flames.querySelector('div.card-flame').cloneNode(true);

box_flames.querySelector('div.card-flame').classList.add('d-none');

burningWizard.addEventListener('show.mdb.modal', async (e) =>
{
	
});

burningWizard.addEventListener('hidden.mdb.modal', async (e) =>
{
	if (stepper.activeStepIndex === 6)
	{
		await finishWizard();
	}
});

(() => {
	let currentStep = 0;
	let currentStepTimeout;

	const wizard = $('#stepper-burning-wizard');

	wizard.addEventListener('onChangeStep.mdb.stepper', event =>
	{

		clearTimeout(currentStepTimeout);
		currentStepTimeout = setTimeout(() =>
		{
			if (stepper.activeStepIndex !== currentStep)
			{
				if (5 <= stepper.activeStepIndex)
				{
					lockStepper(stepper.activeStepIndex);
				}

				currentStep = stepper.activeStepIndex;
				changeStep(currentStep);
			}
		}, 1);
	});
})();

async function lockStepper(step)
{
	stepper._options.stepperNoEditable = true;

	for(let i = 0; i < step; i++)
	{
		stepper._steps[i].classList.add('stepper-disabled');
	}
}

async function resetStepper()
{
	stepper._options.stepperNoEditable = false;

	for(let i = 0; i < stepper._steps.length; i++)
	{
		stepper._steps[i].classList.remove('stepper-disabled');
	}
}

async function changeStep(step)
{
	console.log('step', step);

	if(step === 6)
	{
		$('#box-opensea-key').classList.add('d-none');
		$('#spinner-get-key').classList.remove('d-none');
		const keys = await actions.getOwnedKeys();
		console.log('keys', keys);

		const id = Math.max.apply(Math, keys);

		console.log('key id', id);

		$('#link-opensea-key').href = 'https://opensea.io/assets/ethereum/0xF1cA3528D4322290c76f2a8e0738A38fB81F7EA8/' + id;
		$('#box-opensea-key').classList.remove('d-none');
		$('#spinner-get-key').classList.add('d-none');
	}
}

const finishWizard = async () =>
{
	$('#chbox-burn-comics').checked = false;
	btn_burn_comics.classList.remove('d-none');
	box_burning_scene.classList.add('d-none');
	spinner_burn_comics.classList.add('d-none');
	box_comics_burnt.classList.add('d-none');

	if (await checkNftCount())
	{
		renderFlameCards();
		resetStepper();
		stepper.changeStep(4);
		return;
	}

	resetStepper();
	stepper.changeStep(1);
}

let address;

const renderFlameCards = async () =>
{
	spinner_select_flame.classList.remove('d-none');

	box_flames.innerHTML = '';

	const owned = await actions.getOwnedFlames(address);

	owned.forEach(id =>
	{
		const card = card_flame.cloneNode(true);

		card.querySelector('img').src = 'https://nft.futureland.io/flame/image/' + id + '.png';
		card.querySelector('input').value = id;
		card.querySelector('.card-title').textContent = 'Ohýnek ' + id;

		box_flames.appendChild(card);
	});

	console.log(owned);

	spinner_select_flame.classList.add('d-none');
};

const checkNftCount = async () => {
	
	$('#chbox-connected').checked = false;
	$('#text-connect-info').textContent = '';

	const comics_count = await actions.countOwnedComics();
	const flames_count = await actions.countOwnedFlames();

	console.log('comics_count', comics_count);
	console.log('flames_count', flames_count);

	if (flames_count < 1)
	{
		spinner_connect_wallet.classList.add('d-none');

		$('#text-connect-info').textContent = 'Na této adrese nemáš žádný ohýnek.';

		return false;
	}

	if (comics_count < 3)
	{
		spinner_connect_wallet.classList.add('d-none');

		$('#text-connect-info').textContent = 'Na této adrese nemáš ' + (comics_count ? 'dostatek komiksů. Aktuálně v peněžence máš ' + comics_count + ' komiks' + (comics_count > 1 ? 'y' : '') : 'žádný komiks') + '.';

		return false;
	}

	$('#chbox-connected').checked = true;

	return true;
}

$('#btn-wizard-prev').addEventListener('click', async () => stepper.previousStep());
$('#btn-wizard-next').addEventListener('click', async () => {
	if (stepper.activeStepIndex === 6)
	{
		await finishWizard();
		return;
	}

	for(let last = -1; stepper.activeStepIndex < 6 && stepper.activeStepIndex !== last;)
	{
		last = stepper.activeStepIndex;
		stepper.nextStep();
	}
	stepper.nextStep()
});

const spinner_connect_wallet = $('#spinner-connect-wallet');
const spinner_approve_comics = $('#spinner-approve-comics');
const spinner_approve_flame  = $('#spinner-approve-flame');
const spinner_burn_comics = $('#spinner-burn-comics');
const spinner_select_flame = $('#spinner-select-flame');

const message_approve_comics = $('#message-approve-comics');
const message_approve_flame = $('#message-approve-flame');

const btn_connect_wallet = $('#btn-connect-wallet');
const btn_approve_comics = $('#btn-approve-comics');
const btn_approve_flame  = $('#btn-approve-flame');
const btn_burn_comics  = $('#btn-burn-comics');

const box_burning_scene = $('#box-burning-scene');
const box_comics_burnt = $('#box-comics-burnt');


btn_connect_wallet.addEventListener('click', async () =>
{
	spinner_connect_wallet.classList.remove('d-none');

	address = await actions.connect();

	if (address)
	{
		$('#text-address').textContent = 'Adresa: ' + address;

		if (!(await checkNftCount()))
		{
			return;
		}

		spinner_connect_wallet.classList.add('d-none');

		$('#chbox-approved-comics').checked = await actions.isComicsApproved(address);
		$('#chbox-approved-flame').checked = await actions.isFlameApproved(address);
		renderFlameCards();
	}
});

btn_approve_comics.addEventListener('click', async () =>
{
	btn_approve_comics.classList.add('d-none');
	spinner_approve_comics.classList.remove('d-none');
	message_approve_comics.classList.remove('d-none');

	if (!await actions.approveComics())
	{
		message_approve_comics.classList.add('d-none');
		spinner_approve_comics.classList.add('d-none');
		btn_approve_comics.classList.remove('d-none');
		return;
	}

	async function check()
	{
		if (!await actions.isComicsApproved())
		{
			return setTimeout(check, 1000);
		};

		$('#chbox-approved-comics').checked = true;

		message_approve_comics.classList.add('d-none');
		spinner_approve_comics.classList.add('d-none');
		btn_approve_comics.classList.remove('d-none');
	}

	check();
});

btn_approve_flame.addEventListener('click', async () =>
{
	btn_approve_flame.classList.add('d-none');
	spinner_approve_flame.classList.remove('d-none');
	message_approve_flame.classList.remove('d-none');

	if (!await actions.approveFlame())
	{
		message_approve_flame.classList.add('d-none');
		spinner_approve_flame.classList.add('d-none');
		btn_approve_flame.classList.remove('d-none');

		return;
	}

	async function check()
	{
		if (!await actions.isFlameApproved())
		{
			return setTimeout(check, 1000);
		};

		$('#chbox-approved-flame').checked = true;

		message_approve_flame.classList.add('d-none');
		spinner_approve_flame.classList.add('d-none');
		btn_approve_flame.classList.remove('d-none');
	}

	check();
});

box_flames.addEventListener('click', e =>
{
	let b = e.target;

	while(b && !b.classList.contains('card-flame'))
	{
		b = b.parentNode;
	} 

	if (!b)
	{
		return;
	}

	b.querySelector('input').checked = true;
});

btn_burn_comics.addEventListener('click', async () =>
{
	const comics_count = await actions.countOwnedComics();

	console.log('comics_count', comics_count);

	if (!comics_count || comics_count < 3)
	{
		alert('Nemáš dostatek komiksů! Aktuálně máš ' + (comics_count || 0) + ' komiksů');
		return;
	}

	const flame_id = $('input[name="flame_id"]:checked').value;
	
	btn_burn_comics.classList.add('d-none');
	spinner_burn_comics.classList.remove('d-none');
	box_burning_scene.classList.remove('d-none');

	if (!await actions.burnAndMint(flame_id))
	{
		btn_burn_comics.classList.remove('d-none');
		box_burning_scene.classList.add('d-none');
		spinner_burn_comics.classList.add('d-none');

		return;
	}

	$('#chbox-burn-comics').checked = true;
	box_burning_scene.classList.add('d-none');
	spinner_burn_comics.classList.add('d-none');
	box_comics_burnt.classList.remove('d-none');
});
export default {
	mdb,
};
