import * as ethers from 'ethers';

import FutureComicsAbi from './abi/FutureComics';
import FutureFlameAbi from './abi/FutureFlame';
import ComicsBurnerAbi from './abi/FutureComicsBurner';
import FutureKeyOfGodsAbi from './abi/FutureKeyOfGods';
import FutureKeyOfGods from './abi/FutureKeyOfGods';

const FutureFlameAddress = '0xfBc008D3f46026643fc0d8F1088dde3B1d19aBa6';
const FutureKeyOfGodsAddress = '0xF1cA3528D4322290c76f2a8e0738A38fB81F7EA8';
const FutureComicsAddress = '0x495f947276749Ce646f68AC8c248420045cb7b5e';
const ComicsBurnerAddress = '0x6C515e1a76afd27Fb6D9701215b009A1F19f0bD1';

const FutureComicsId = ethers.BigNumber.from('10667520178378707509196689156321488950583476902291880253708054277517725402112');

async function connect() {
	if (typeof window.ethereum === 'undefined')
	{
		console.log('No Ethereum!');
		return;
	}

	try {
		await window.ethereum.request({ method: 'eth_requestAccounts' });
	}
	catch (error) {
		console.log(error);
		return null;
	}

	return (await window.ethereum.request({ method: 'eth_accounts' }))[0];
}

async function isComicsApproved() {
	if (typeof window.ethereum === 'undefined') {
		return;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();
	const contract = new ethers.Contract(FutureComicsAddress, FutureComicsAbi, signer);

	try {
		return await contract.isApprovedForAll(signerAddress, ComicsBurnerAddress);
	}
	catch (error) {
		console.log(error);
	}

	return false;
}

async function approveComics() {
	if (typeof window.ethereum === 'undefined') {
		return false;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const contract = new ethers.Contract(FutureComicsAddress, FutureComicsAbi, signer);

	try {
		await contract.setApprovalForAll(ComicsBurnerAddress, true);
		return true;
	}
	catch (error)
	{
		console.log(error);
	}

	return false;
}

async function isFlameApproved() {
	if (typeof window.ethereum === 'undefined') {
		return;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();
	const contract = new ethers.Contract(FutureFlameAddress, FutureFlameAbi, signer);

	try {
		return await contract.isApprovedForAll(signerAddress, ComicsBurnerAddress);
	}
	catch (error) {
		console.log(error);
	}

	return false;
}

async function approveFlame() {
	if (typeof window.ethereum === 'undefined') {
		return false;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const contract = new ethers.Contract(FutureFlameAddress, FutureFlameAbi, signer);

	try
	{
		await contract.setApprovalForAll(ComicsBurnerAddress, true);
		return true;
	}
	catch (error)
	{
		console.log(error);
	}

	return false;
}

async function countOwnedComics()
{
	if (typeof window.ethereum === 'undefined')
	{
		return;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();
	const comics = new ethers.Contract(FutureComicsAddress, FutureComicsAbi, signer);

	try
	{
		return (await comics.balanceOf(signerAddress, FutureComicsId)).toNumber();
	}
	catch (error)
	{
		console.log(error);
	}
}

async function countOwnedFlames()
{
	if (typeof window.ethereum === 'undefined')
	{
		return;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();
	const comics = new ethers.Contract(FutureFlameAddress, FutureFlameAbi, signer);

	try
	{
		return (await comics.balanceOf(signerAddress)).toNumber();
	}
	catch (error)
	{
		console.log(error);
	}
}

async function countOwnedKeys()
{
	if (typeof window.ethereum === 'undefined')
	{
		return;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();
	const flame = new ethers.Contract(FutureKeyOfGodsAddress, FutureKeyOfGodsAbi, signer);

	try
	{
		return (await flame.balanceOf(signerAddress)).toNumber();
	}
	catch (error)
	{
		console.log(error);
	}
}

async function getOwnedFlames()
{
	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();
	const contract = new ethers.Contract(FutureFlameAddress, FutureFlameAbi, signer);
	
	const result = {};

	return new Promise(resolutionFunc => {
		const f = i => owner => {
			result[i] = (owner && owner.toLowerCase() === signerAddress.toLowerCase());

			if (Object.keys(result).length === 85)
			{
				resolutionFunc(Object.entries(result).filter(i => i[1]).map(i => i[0]));
			}
		};
	
		for(let i = 1; i <= 85; i++)
		{
			try
			{
				(f=>contract.ownerOf(i).then(f,f))(f(i));
			}
			catch (error)
			{
				f(i)();
			}
		}
	});
}

async function getOwnedKeys()
{
	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();
	const contract = new ethers.Contract(FutureKeyOfGodsAddress, FutureKeyOfGodsAbi, signer);
	
	const result = {};

	return new Promise(async resolutionFunc => {

		const total = (await contract.totalMinted()).toNumber();

		console.log('total', total);

		if (!total)
		{
			return resolutionFunc([]);
		}

		const f = i => owner => {
			result[i] = (typeof(owner) === 'string' && owner.toLowerCase() === signerAddress.toLowerCase());

			if (Object.keys(result).length === total)
			{
				resolutionFunc(Object.entries(result).filter(i => i[1]).map(i => i[0]));
			}
		};

		for(let i = 1; i <= total; i++)
		{
			try
			{
				(f=>contract.ownerOf(i).then(f,f))(f(i));
			}
			catch (error)
			{
				f(i)();
			}
		}
	});
}

async function burnAndMint(flame_id) {
	if (typeof window.ethereum === 'undefined')
	{
		document.getElementById('BurnAndMintButton').innerHTML = 'Please install MetaMask';
		return false;
	}

	const provider = new ethers.providers.Web3Provider(window.ethereum);
	const signer = provider.getSigner();
	const signerAddress = await signer.getAddress();

	const comics = new ethers.Contract(FutureComicsAddress, FutureComicsAbi, signer);
	const flame = new ethers.Contract(FutureFlameAddress, FutureFlameAbi, signer);

	const contract = new ethers.Contract(ComicsBurnerAddress, ComicsBurnerAbi, signer);

	try {
		console.log('Spáleno komiksů:', await comics.balanceOf('0x000000000000000000000000000000000000dEaD', ethers.BigNumber.from('10667520178378707509196689156321488950583476902291880253708054277517725402112')));
		console.log('Spáleno ohýnků:', await flame.balanceOf('0x000000000000000000000000000000000000dEaD'));

		const owned_before = (await flame.balanceOf(signerAddress)).toNumber();
		let owned_now = owned_before;

		await contract.burnAndMint(flame_id);

		while (owned_before === owned_now)
		{
			owned_now = (await flame.balanceOf(signerAddress)).toNumber();
		}

		console.log('Spáleno komiksů:', await comics.balanceOf('0x000000000000000000000000000000000000dEaD', ethers.BigNumber.from('10667520178378707509196689156321488950583476902291880253708054277517725402112')));
		console.log('Spáleno ohýnků:', await flame.balanceOf('0x000000000000000000000000000000000000dEaD'));

		return true;
	}
	catch (error)
	{
		console.log(error);
	}

	return false;
}

export default {
	connect,
	isComicsApproved,
	approveComics,
	isFlameApproved,
	approveFlame,
	countOwnedComics,
	countOwnedFlames,
	countOwnedKeys,
	getOwnedFlames,
	getOwnedKeys,
	burnAndMint,
};
